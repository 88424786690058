import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import MaterialTable, { MTableToolbar } from 'material-table'
import { TableIcons } from '../../ui/TableIcons'
import { Tooltip, IconButton, Grid, Card, CardContent, Select, MenuItem, LinearProgress, Link, Box, Button, makeStyles } from '@material-ui/core'

import BuildIcon from '@material-ui/icons/Build'
import LaunchIcon from '@material-ui/icons/Launch'
import GetAppIcon from '@material-ui/icons/GetApp'
import SettingsIcon from '@material-ui/icons/Settings'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import { CustomDialog } from '../../ui'
import { commonStyle } from '../../../styles'
import { clone, siteNameFormatter, cacheBuster } from '../../../utilities/helperFunctions.js'
import { fetchAdFuelModules } from '../../../modules/adFuelModuleSlice'
import { updateSiteAdFuelModules } from '../../../modules/sitesSlice'
import { getAdFuelModuleConfiguration, getAdFuelModuleConfigurations, getAdFuelModuleCoreConfiguration } from '../../../modules/lookupsSlice'

import { AdFuelOptions } from '../index.js'
import semver from 'semver'
import MarkdownIt from 'markdown-it'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints.js'

const swal = withReactContent(Swal)

const useStyle = makeStyles(theme => ({
  footerActionArea: {
    display: 'flex',
    padding: theme.spacing(1)
  },
  iconButtons: {
    marginLeft: theme.spacing(1)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  dflex: {
    display: 'flex'
  }
}))

const cellStyle = {
  padding: '0 10px'
}

const centeredCellStyle = {
  padding: '0 10px',
  textAlign: 'center',
  justify: 'middle'
}

const initialAdFuelModule = {
  name: '',
  description: '',
  url: '',
  versions: [],
  arguments: []
}

const AISConfiguration = ({ data, canEditSite }) => {
  const classes = useStyle()
  const commonClasses = commonStyle()
  const [site, setSite] = useState(clone(data))
  let { aisUrl, adFuelModules, requiredModules } = site
  const { site: rawSite } = useSelector(state => state.sitesSlice)
  const { updatingSiteAdFuelModules } = useSelector(state => state.sitesSlice)
  const { adFuelModules: allAdFuelModules } = useSelector(state => state.adFuelModulesSlice)
  const { adFuelVersions } = useSelector(state => state.adFuelSlice)
  const [moduleList, setModuleList] = useState(site.moduleList)
  //const [moduleList, setModuleList] = useState([])
  const [allAdFuelModuleList, setAllAdFuelModuleList] = useState([])
  const [isChanged, setIsChanged] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [dialog, setDialog] = useState({ content: '' })
  const [loading, setLoading] = useState(true)
  const [aisUrlWithCacheBuster, setAisUrlWithCacheBuster] = useState(aisUrl)
  const [clickCount, setClickCount] = useState(0)
  const dispatch = useDispatch()
  const { adFuelVersionId } = data
  const [selectedAdFuelModuleList, setSelectedAdFuelModuleList] = useState([])
  const [siteAdFuelVersion, setSiteAdFuelVersion] = useState()
  const [latest, setLatest] = useState()

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => {
    updateState({})
  }, [])

  const mdParser = new MarkdownIt(/* Markdown-it options */)

  const byPriorityAndName = (a, b) => {
    if (a.adFuelModuleId && b.adFuelModuleId) {
      const moduleA = getModule(a.adFuelModuleId)
      const moduleB = getModule(b.adFuelModuleId)
      if (moduleA && moduleB) {
        const nameA = moduleA.name.toUpperCase()
        const nameB = moduleB.name.toUpperCase()
        const prioA = moduleA.priority !== null ? parseInt(moduleA.priority || 0, 10) : 0
        const prioB = moduleB.priority !== null ? parseInt(moduleB.priority || 0, 10) : 0
        try {
          if (prioA > prioB) {
            return -1
          }
          if (prioA < prioB) {
            return 1
          }
          if (prioA === prioB) {
            if (nameA > nameB) {
              return 1
            }
            if (nameA < nameB) {
              return -1
            }
          }
          return 0
        } catch (err) {
          return 0
        }
      }
    }
  }

  const getModulesForAdFuelVersion = (modules, site) => {
    const result = []
    const siteAdFuelVersionId = site.adFuelVersionId
    const siteAdFuelVersion = adFuelVersions.filter(version => version._id === siteAdFuelVersionId)[0]
    if (siteAdFuelVersion) {
      setSiteAdFuelVersion(siteAdFuelVersion)
      let latest = false
      const { major, minor, revision } = siteAdFuelVersion
      if (revision === null || siteAdFuelVersion.name.toLowerCase().indexOf('latest') > 0) latest = true
      setLatest(latest)
      modules.forEach(module => {
        // console.groupCollapsed(module.name)
        const moduleAdFuelVersionId = module.adFuelVersionId
        if (moduleAdFuelVersionId) {
          const moduleAdFuelVersion = adFuelVersions.filter(version => version._id === moduleAdFuelVersionId)[0]
          let modLatest = false
          if (moduleAdFuelVersion) {
            const { major: modMajor, minor: modMinor, revision: modRevision } = moduleAdFuelVersion
            if (modRevision === null || moduleAdFuelVersion.name.toLowerCase().indexOf('latest') > 0) modLatest = true
            if (moduleAdFuelVersion && modMajor === major && modMinor <= minor) {
              // console.log('AdFuel Major and Module Major are equal and Module Minor is less than or equal to AdFuel Minor', { major, minor, revision, latest, modMajor, modMinor, modRevision, modLatest })
              if (!latest && !modLatest && modRevision <= revision) {
                // console.log('AdFuel and Module is not latest and modRevision is less than or equal to AdFuel revision... Pushing ', { major, minor, revision, latest, modMajor, modMinor, modRevision, modLatest })
                result.push(module)
              }
              if (latest || modLatest) {
                // console.log('AdFuel or Module are latest... Pushing', { major, minor, revision, latest, modMajor, modMinor, modRevision, modLatest })
                result.push(module)
              }
            } else {
              // console.log('AdFuel Major and Module Major are not equal or Module Minor is greater than AdFuel Minor... Not Pushing', { major, minor, revision, latest, modMajor, modMinor, modRevision, modLatest })
            }
          }
        } else {
          const newModule = { ...module }
          // console.log('No Module AdFuel Version... Pushing', { major, minor, revision, latest, module, moduleAdFuelVersionId })
          result.push(newModule)
        }
        // console.groupEnd()
      })
    }
    setModuleList([...result])
    setSelectedAdFuelModuleList([...result])
  }

  const setModuleVersions = (site) => {
    let response = clone(site)
    response.adFuelModules = site.adFuelModules.map(afMod => {
      let match = allAdFuelModules.find(ogMod => {
        return ogMod._id === afMod.adFuelModuleId
      })

      let versions = match && match.versions ? match.versions : []
      let version = versions.find(modVer => {
        return modVer._id === afMod.adFuelModuleVersionId
      })
      afMod.version = version && version.name ? version.name : '0.0.0'
      return afMod
    })
    return response
  }  

  useEffect(() => {
    if (allAdFuelModules.length === 0) {
      // console.log('No modules... fetching AdFuel Modules...')
      dispatch(fetchAdFuelModules())
    }
  }, [])

  useEffect(() => {
    setAisUrlWithCacheBuster(aisUrl + `?cacheBuster=${cacheBuster()}`)
  }, [aisUrl])
  
  useEffect(() => {
    const allAdFuelModulesCopy = [...allAdFuelModules]
    setAllAdFuelModuleList(allAdFuelModulesCopy)
    if (!adFuelModules || adFuelModules.length === 0) { 
      adFuelModules = clone(rawSite.adFuelModules)
    }
    const modList = allAdFuelModules.filter(
      adFuelModule =>
        !adFuelModules?.find(
          ({ adFuelModuleId }) => adFuelModule._id === adFuelModuleId
        )
    )
    setSelectedAdFuelModuleList(modList)
    getModulesForAdFuelVersion(modList, site)
    if (moduleList?.length === 0) {
      setModuleList(modList)
    }
    setLoading(false)
  }, [allAdFuelModules, adFuelModules])

  useEffect(() => {
    console.log('useEffect []',data)
    setSite(clone(data))
  }, [data])

  useEffect(() => {
    if (site._id) {
      const oldData = { aisUrl: data.aisUrl, adFuelModules: data.adFuelModules, rm: data.requiredModules }
      const newData = { aisUrl: site.aisUrl, adFuelModules: site.adFuelModules, rm: site.requiredModules }
      console.log('useEffect site,adFuelModules', oldData, newData)
      setIsChanged(JSON.stringify(oldData) !== JSON.stringify(newData))
    }
    if (adFuelModules) {
      setModuleList([...adFuelModules])
    }
  }, [site, adFuelModules])

  useEffect(() => {
    if (site._id) {
      setAisUrlWithCacheBuster(aisUrl + `?cacheBuster=${cacheBuster()}`)
    }
  }, [clickCount])

  const getModule = adFuelModuleId => {
    const module = allAdFuelModuleList.find(item => item._id === adFuelModuleId)
    return !module ? '' : module
  }

  const getAISFilename = () => `${siteNameFormatter(site.name)}-ais.js`

  const openViewDialog = jsFile => {
    setDialog({
      title: getAISFilename(),
      content: (
        <CardContent>
          <pre>{jsFile}</pre>
        </CardContent>
      )
    })
    setOpenDialog(true)
  }

  const openReleaseNotesDialog = async (releaseNotes) => {
    const onClose = () => {
      setOpenDialog(false)
    }
    setDialog({
      content: (
        <Card>
          <CardContent dangerouslySetInnerHTML={{ __html: mdParser.render(releaseNotes) }} />
        </Card>
      ),
      displayDialogAction: false,
      onClose
    })
    setOpenDialog(true)
  }

  const openConfigurationDialog = async (moduleId, moduleName, moduleIsConfigurable) => {    
    const setVersion = (siteModuleId, versionId, versionName) => {
      const newSite = { ...site }
      newSite.adFuelModules = site.adFuelModules.map(module => {
        if (module.adFuelModuleId === siteModuleId) { 
          module.adFuelModuleVersionId = versionId
          module.version = versionName
        }
        return module
      })
      setSite(newSite)
    }

    const onClose = () => {
      if (moduleIsConfigurable) {
        swal.fire({
          title: 'Are you sure?',
          text: 'Changes will not be saved.',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: 'No, Do Not Cancel.',
          confirmButtonText: 'Yes, Discard my changes.',
          customClass: {
            container: 'swal2-container'
          },
          style: { zIndex: 99999 }
          // buttons: true,
          // dangerMode: true
        }).then(ok => {
          // console.log('Cancel response: ', ok)
          if (ok.isConfirmed) { setOpenDialog(false) }
        })
      } else {
        setOpenDialog(false)
      }
    }
    try {
      const { data: options } = await getAdFuelModuleConfigurations(site._id)
      let response = { data: [] }
      if (moduleIsConfigurable) {
        response = await getAdFuelModuleConfiguration(site._id, moduleId)
        const core = await getAdFuelModuleCoreConfiguration()
        // console.log('Core Options: ', core)
        const mergedData = { ...core, ...options }
        if (moduleName) {
          console.log('Module Name: ', moduleName)
          console.log('Size: xl')
          setDialog({
            content: (<AdFuelOptions allData={mergedData} module={getModule(moduleId)} moduleId={moduleId} moduleName={moduleName} data={response.data} site={site} setOpenDialog={setOpenDialog} onClose={onClose} setVersion={setVersion} />),
            displayDialogAction: false,
            size: 'xl',
            onClose
          })
          setOpenDialog(true)
        } else {
          console.log('No Module Name: ', moduleName)
          swal.fire({
            title: 'Error',
            text: `Options not found -- Site: ${site._id} -- Module: ${moduleId} --`,
            icon: 'error',
            customClass: {
              container: 'swalContainer'
            }
          })
        }
      }
    } catch (err) {
      swal.fire({
        title: 'Error',
        text: err,
        icon: 'error',
        customClass: {
          container: 'swalContainer'
        }
      })
    }
  }

  const deleteModule = async moduleId => {
    if (moduleId === undefined) {
      const index = adFuelModules.findIndex(x => x.adFuelModuleId === undefined)
      // console.log('index', index)
      if (index !== -1) {
        adFuelModules.splice(index, 1)
        setSite({
          ...site,
          adFuelModules
        })
      }
    } else {
      setSite({
        ...site,
        adFuelModules: adFuelModules.filter(
          module => module.adFuelModuleId !== moduleId
        )
      })
    }
  }

  return (
    <>
      {!loading
        ? (
          <MaterialTable
            title='AIS Configuration'
            icons={TableIcons}
            columns={[
              {
                title: 'Name',
                cellStyle,
                render: rowData => {
                  const { adFuelModuleId, tableData } = rowData
                  const index = tableData.id
                  const module = getModule(adFuelModuleId)

                  const nonselectedAdFuelModuleList = selectedAdFuelModuleList?.slice()
                  if (nonselectedAdFuelModuleList.indexOf(module) === -1) {
                    nonselectedAdFuelModuleList.push(module)
                  }
                  nonselectedAdFuelModuleList.sort((a, b) => {
                    if (a.name?.toUpperCase() < b.name?.toUpperCase()) return -1
                    if (b.name?.toUpperCase() < a.name?.toUpperCase()) return 1
                    return 0
                  })
                  return (
                    <Select
                      disabled={!canEditSite}
                      fullWidth
                      value={module}
                      onChange={event => {
                        const updatedModule = { ...clone(event.target.value) }
                        // console.log('Updated Module: ', updatedModule)
                        // console.log('Index: ', index)
                        // console.log('AdFuelModule at Index: ', adFuelModules[index])
                        adFuelModules[index] = {
                          name: updatedModule.name,
                          adFuelModuleId: updatedModule._id,
                          adFuelModuleVersion: null,
                          adFuelModuleVersionId: null,
                          priority: updatedModule.priority
                        }
                        // dispatch(updateSiteAdFuelModules({ ...site, adFuelModules }))
                        // console.log('Setting site: ', { ...site, adFuelModules })
                        setSite({ ...site, adFuelModules })
                        setModuleList(adFuelModules)
                        forceUpdate()

                        const moduleList = allAdFuelModules.filter(
                          adFuelModule =>
                            !adFuelModules.find(
                              ({ adFuelModuleId }) =>
                                adFuelModule._id === adFuelModuleId
                            )
                        ).sort((a, b) => {
                          if (a.priorty > b.priorty) return 1
                          if (a.name > b.name) return 1
                          if (b.priority > a.priority) return -1
                          if (b.name > a.name) return -1
                          return 0
                        })
                        setSelectedAdFuelModuleList(moduleList)
                      }}
                    >
                      {nonselectedAdFuelModuleList.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  )
                }
              },
              {
                title: 'Source',
                centeredCellStyle,
                render: rowData => {
                  const { adFuelModuleId, adFuelModuleVersionId } = rowData
                  const module = getModule(adFuelModuleId)
                  let render = 'N/A'
                  if (module) {
                    const version = module?.versions.find(item => item._id === adFuelModuleVersionId)
                    const url = version ? `https://i.cdn.turner.com${version.url}` : module.url
                    const filename = url.split('/')[url.split('/').length - 1]
                    // console.log('Module: ', { url, filename })
                    // render = (<Link className={commonClasses.linkColor} href={module ? url : ''} target='_blank' rel='noopener noreferrer'>{!module ? 'not found' : filename}</Link>)
                    render = (
                      <div style={centeredCellStyle}>
                        <Link className={commonClasses.linkColor} href={module ? url : ''} target='_blank' rel='noopener noreferrer'>
                          <Tooltip title={!module ? 'not found' : filename}>
                            <LaunchIcon className={commonClasses.linkIconColor}/>
                          </Tooltip>
                        </Link>
                      </div>
                    )
                  }
                  return render
                }
              },
              {
                title: 'Version',
                cellStyle,
                render: (rowData) => {
                  const { adFuelModuleId, tableData, adFuelModuleVersionId } = rowData
                  const index = tableData.id
                  const module = getModule(adFuelModuleId)
                  // console.log('AdFuelModuleVersionId: ', adFuelModuleVersionId)
                  // console.log('Module: ', module)
                  let render = 'N/A'
                  let updateAvailable = true
                  let versionSet = false

                  if (module) {
                    const newModule = JSON.parse(JSON.stringify({ ...module }))
                    if (newModule.versions) {
                      const { major, minor, revision } = siteAdFuelVersion || { major: 0, minor: 0, revision: 0 }
                      newModule.versions = newModule.versions.map(version => {
                        const moduleVersionAdFuelVersionId = version.adFuelVersionId
                        if (moduleVersionAdFuelVersionId) {
                          const moduleVersionAdFuelVersion = adFuelVersions.filter(version => version._id === moduleVersionAdFuelVersionId)[0]
                          const moduleLatest = !moduleVersionAdFuelVersion?.revision
                          // console.log('AdFuel Latest: ', latest)
                          // console.log('Module Latest: ', moduleLatest)
                          if (moduleVersionAdFuelVersion && moduleVersionAdFuelVersion.major === major && moduleVersionAdFuelVersion.minor <= minor) {
                            if (!latest && moduleVersionAdFuelVersion.revision <= revision) {
                              // console.log('Version is good: ', { moduleVersionAdFuelVersion, version, major, minor, revision })
                              return version
                            } else if (latest || moduleLatest) {
                              // console.log('Version is good: ', { moduleVersionAdFuelVersion, version, major, minor, revision })
                              return version
                            } else {
                              // console.log('Version is no good: ', { moduleVersionAdFuelVersion, version, major, minor, revision })
                              return null
                            }
                          }
                        }
                        return null
                      }).filter(item => item !== null && typeof item !== 'undefined')
                      newModule.versions.sort((a, b) => {
                        if (semver.gt(a.name, b.name)) return -1
                        if (semver.gt(b.name, a.name)) return 1
                        return 0
                      })
                    }
                    const latestVer = newModule.versions.length > 0 ? newModule.versions[0] : 'Unknown'
                    let _adFuelModuleVersionId = adFuelModuleVersionId
                    if (!adFuelModuleVersionId) { _adFuelModuleVersionId = latestVer._id }
                    const version = newModule.versions.filter(version => version._id === _adFuelModuleVersionId)[0]?.name || 'No Version Set'
                    versionSet = version !== 'No Version Set'
                    if (_adFuelModuleVersionId === latestVer._id) updateAvailable = false
                    const style = {
                      textAlign: 'center'
                    }
                    if (!versionSet) style.color = 'red'
                    if (newModule) {
                    // const version = module.versions.find(item => item._id === adFuelModuleId)
                      render = (<div style={style}>{version}{updateAvailable && versionSet ? (<div className={commonClasses.updateAvailable} style={{ fontSize: '.8em' }}>Update Available!</div>) : ''}</div>)
                    }
                    if (canEditSite) {
                      return (
                        <>
                          <Select
                            fullWidth
                            disabled={!canEditSite}
                            value={_adFuelModuleVersionId}
                            onChange={event => {
                              const moduleVersion= clone(event.target.value)                              
                              // console.log('------ Updated Module Version: ', moduleVersion)
                              // console.log('------ AdFuelModule at Index: ', adFuelModules[index])
                              adFuelModules[index].adFuelModuleVersionId = moduleVersion
                              adFuelModules[index].version = newModule?.versions.map(item => {
                                return item._id === moduleVersion ? item.name : null
                              }).filter(item => item !== null)[0]
                              // dispatch(updateSiteAdFuelModules({ ...site, adFuelModules }))
                              // console.log('------ Setting site: ', { ...site, adFuelModules })
                              setSite({ ...site, adFuelModules })
                              setModuleList(adFuelModules)
                              getModulesForAdFuelVersion(moduleList, site)
                              forceUpdate()
                            }}
                          >
                            {/* <MenuItem key={'latest'} value={module.versions[0]._id}>Latest</MenuItem> */}
                            {newModule?.versions.map((item, index) => {
                              return (<MenuItem key={index} value={item._id}>{item.name}</MenuItem>)
                            })}
                          </Select>
                          {updateAvailable && versionSet ? (<div className={commonClasses.updateAvailable} style={{ textAlign: 'center', fontSize: '.8em' }}>Update Available!</div>) : ''}
                          {!versionSet ? (<div style={{ textAlign: 'center', fontSize: '.8em', color: 'red' }}>No Version Set. Please Select Version.</div>) : ''}
                        </>
                      )
                    }
                  }
                  return render
                }
              },
              {
                title: 'Priority',
                centeredCellStyle,
                render: rowData => {
                  const { adFuelModuleId, priority } = rowData
                  // return priority
                  const module = getModule(adFuelModuleId)
                  let render = priority !== null ? JSON.stringify(priority) : 'N/A'
                  if (module) {
                    render = JSON.stringify(module.priority) || render
                  }
                  const element = (<div style={centeredCellStyle}>{render}</div>)
                  return element
                }
              },
              {
                title: 'Rqd',
                centeredCellStyle,
                render: rowData => {
                  let { adFuelModuleId, isRequired } = rowData
                  const module = getModule(adFuelModuleId)
                  for(let x of site.requiredModules ?? []) {
                    if (x === module.logKey) {
                      isRequired = true
                      break
                    }
                  }

                  if (module.logKey) {
                    return (
                      <div style={centeredCellStyle}>
                      <input 
                        type="checkbox"
                        defaultChecked={isRequired}
                        onChange = {(e) => {
                          let filtered = clone(site.requiredModules?.filter(element => element && element !== module.logKey) ?? [])
                          if (e.target.checked) {
                            filtered.push(module.logKey)
                          }
                          setSite({ ...site, adFuelModules, requiredModules: filtered })
                        }
                      }/>
                      </div>
                    )
                  } else {
                    return <div style={centeredCellStyle}>N/A</div>
                  }
                }
              },
              {
                title: 'Actions',
                cellStyle,
                render: rowData => {
                  const { adFuelModuleId, adFuelModuleVersionId } = rowData
                  const module = getModule(adFuelModuleId)
                  let render = (
                    <IconButton
                      aria-label='Delete Module'
                      onClick={() => {
                        deleteModule(undefined)
                      }}
                    >
                      <DeleteIcon className={commonClasses.deleteIconColor} />
                    </IconButton>
                  )
                  if (module) {
                    const version = module.versions.find(item => item._id === adFuelModuleVersionId)
                    const key = module.configurationKey
                    const hasReleaseNotes = (version && version.releaseNotes && version.releaseNotes !== null) || false
                    render = (
                      <Grid container>
                        <Grid className={classes.dflex} item>
                          <IconButton
                            aria-label='Delete Module'
                            title='Delete Module'
                            onClick={() => {
                              if (canEditSite) {
                                deleteModule(module._id)
                              } else {
                                swal.fire({
                                  title: 'UNAUTHORIZED',
                                  text: 'You do not have permissions to do this.',
                                  icon: 'error',
                                  dangerMode: false,
                                  customClass: {
                                    container: 'swalContainer'
                                  }
                                })
                              }
                            }}
                          >
                            <DeleteIcon className={commonClasses.deleteIconColor} />
                          </IconButton>
                          {
                            module.isConfigurable
                              ? (
                                <IconButton
                                  aria-label='Configure Module'
                                  title='Configure Module'
                                  onClick={() => {
                                    if (canEditSite) {
                                      openConfigurationDialog(module._id, key, module.isConfigurable)
                                    } else {
                                      swal.fire({
                                        title: 'UNAUTHORIZED',
                                        text: 'You do not have permissions to do this.',
                                        icon: 'error',
                                        dangerMode: false,
                                        customClass: {
                                          container: 'swalContainer'
                                        }
                                      })
                                    }
                                  }}
                                >
                                  <SettingsIcon />
                                </IconButton>
                                )
                              : ''
                          }
                          {
                            hasReleaseNotes
                              ? (
                                <IconButton
                                  aria-label='Release Notes'
                                  title='Release Notes'
                                  onClick={e => {
                                    e.stopPropagation()
                                    openReleaseNotesDialog(version.releaseNotes)
                                  }}
                                >
                                  <InfoIcon />
                                </IconButton>
                                )
                              : ''
                          }
                        </Grid>
                      </Grid>

                    )
                  }
                  return render
                }
              }
            ]}
            data={moduleList}
            localization={{
              body: { emptyDataSourceMessage: '' }
            }}
            options={{
              search: false,
              emptyRowsWhenPaging: false,
              pageSize: 100,
              draggable: false,
              sorting: false,
              headerStyle: {
                textAlign: 'center',
                fontWeight: 'bold'
              }
            }}
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <Box pl={3}>
                    <Grid container spacing={1} alignItems='center'>
                      <Grid item>
                        <Link
                          className={commonClasses.linkColor}
                          href={!aisUrlWithCacheBuster ? aisUrl : aisUrlWithCacheBuster}
                          target='siteAIS' rel='noopener noreferrer'
                          onClick={() => {
                            setClickCount(clickCount + 1)
                          }}
                        >
                          {!aisUrl ? '' : aisUrl}
                        </Link>
                      </Grid>
                      <Grid item>
                        <Tooltip title='View'>
                          <IconButton
                            size='small'
                            color='primary'
                            className={commonClasses.linkColor}
                            aria-label='view'
                            onClick={async () => {
                              try {
                                const jsFile = await (await fetch(aisUrl.replace('http:', 'https:'))).text()
                                console.log('err', jsFile)
                                openViewDialog(jsFile)
                              } catch (err) {
                              // console.log('err', err)
                              }
                            }}
                          >
                            <LaunchIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip title='Download'>
                          <IconButton
                            size='small'
                            color='primary'
                            className={commonClasses.linkColor}
                            aria-label='download'
                            onClick={async () => {
                              try {
                                const jsFile = await (await fetch(aisUrl.replace('http:', 'https:'))).text()
                                const file = new window.Blob([jsFile], { type: 'text/javascript' })
                                const link = document.createElement('a')
                                link.download = getAISFilename()
                                link.href = URL.createObjectURL(file)
                                link.click()
                              } catch (err) {
                              // console.log('err', err)
                              }
                            }}
                          >
                            <GetAppIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              ),
              Pagination: props => {
                return (
                  <>
                    <td />
                    <td className={classes.footerActionArea}>
                      <Grid container spacing={1} justifyContent='flex-end'>
                        <Grid item>
                          <Button
                            className={commonClasses.btnSuccess}
                            variant='contained'
                            disabled={updatingSiteAdFuelModules || !canEditSite}
                            startIcon={<BuildIcon />}
                            onClick={() => {
                              const saveAndBuild = () => {
                                const adFuelModules = [...site.adFuelModules].sort(byPriorityAndName)
                                site.adFuelModules.sort(byPriorityAndName)
                                setSite({ ...site })
                                setModuleList(adFuelModules)
                                let siteData = clone(site)
                                siteData = setModuleVersions(siteData)
                                dispatch(updateSiteAdFuelModules(siteData))
                              }
                              if (!isChanged) {
                                swal.fire({
                                  title: 'No changes were made to the list of modules included in AIS.',
                                  text: 'Do you still want to rebuild and publish AIS?',
                                  icon: 'info',
                                  confirmButtonText: 'Yes',
                                  cancelButtonText: 'No',
                                  showCancelButton: true
                                }).then(ok => {
                                  if (ok.isConfirmed) { 
                                    saveAndBuild() 
                                  }
                                })
                              } else {
                                saveAndBuild()
                              }
                            }}
                          >
                            {updatingSiteAdFuelModules ? 'Saving...' : 'Save & Build'}
                          </Button>
                        </Grid>
                      </Grid>
                    </td>
                  </>
                )
              }
            }}
            editable={canEditSite && {
            // onRowDelete: (item) => new Promise((resolve) => {
            //   setTimeout(() => {
            //     setSite({ ...site, adFuelModules: adFuelModules.filter(module => module.adFuelModuleId !== item.adFuelModuleId) })
            //     resolve()
            //   }, 1000)
            // })
            }}
            actions={canEditSite
              ? [
                  {
                    icon: TableIcons.Add,
                    tooltip: 'Add Module',
                    isFreeAction: true,
                    onClick: () => {
                      if (adFuelVersionId) {
                        site.adFuelModules = [clone(initialAdFuelModule), ...adFuelModules]
                        setSite({ ...site })
                      } else {
                        swal.fire({
                          title: 'AdFuel Not Selected',
                          icon: 'warning',
                          button: 'Ok',
                          text: 'You must select an AdFuel Version first.',
                          customClass: {
                            container: 'swalContainer'
                          }
                        })
                      }
                    }
                  }
                ]
              : []}
          />
          )
        : (<LinearProgress />)}
      <CustomDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        title={dialog.title}
        subtitle={dialog.subtitle}
        content={dialog.content}
        displayDialogAction={dialog.displayDialogAction}
        customAction={dialog.customAction}
        size={dialog.size}
        handleOnClose={dialog.onClose}
      />
    </>
  )
}

export default AISConfiguration